import React from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';
import { FormattedMessage } from 'react-intl';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionTextMaybe = props => {
  const { text, heading, keyValue, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });

  const label = keyValue? <FormattedMessage id={`CustomExtendedDataField.${keyValue}`}/> : <FormattedMessage id="ListingPage.descriptionTitle"/> ;

  return text ? (
    <section className={css.sectionText}>
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {label}
        </Heading>
      <p className={textClass}>{content}</p>
    </section>
  ) : null;
};

export default SectionTextMaybe;
